module.exports = [{
      plugin: require('/opt/bitnami/apache2/htdocs/gatsby-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-3197797-1"},
    },{
      plugin: require('/opt/bitnami/apache2/htdocs/gatsby-site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/bitnami/apache2/htdocs/gatsby-site/node_modules/gatsby-plugin-glamor/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/bitnami/apache2/htdocs/gatsby-site/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/opt/bitnami/apache2/htdocs/gatsby-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
