// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/bitnami/apache2/htdocs/gatsby-site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-js": () => import("/opt/bitnami/apache2/htdocs/gatsby-site/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-index-js": () => import("/opt/bitnami/apache2/htdocs/gatsby-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pages-js": () => import("/opt/bitnami/apache2/htdocs/gatsby-site/src/pages/pages.js" /* webpackChunkName: "component---src-pages-pages-js" */)
}

